import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import CssBaseline from "@material-ui/core/CssBaseline"
import AppBar from "@material-ui/core/AppBar"
import Box from "@material-ui/core/Box"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DetailsIcon from "@material-ui/icons/Details"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import MailIcon from "@material-ui/icons/Mail"
import Upload from "./upload"
import Dvnameplate from "./dvnameplate"
import Dvinstantaneous from "./dvinstantaneous"
import Dvresetbackup from "./dvresetbackup"
import Dvbillingtod from "./dvbillingtod"
import Dvcumulativetamper from "./dvcumulativetamper"
import Dvtamper from "./dvtamper"
import Dvdailyload from "./dvdailyload"
import Dvblockload from "./dvloadsurvey"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
}))

export default function PermanentDrawerLeft() {
  const classes = useStyles()
  const [filedata, setfiledata] = useState("")
  const obisTable = [
    ["0000010000FF", "Real Date and Time"],
    ["01000C0700FF", "Voltage"],
    ["01005E5B0EFF", "Current"],
    ["01000B0700FF", "Phase Current"],
    ["01005B0700FF", "Neutral Current"],
    ["01000D0700FF", "Signed PF"],
    ["01000E0700FF", "Frequency"],
    ["0100090700FF", "Apparent Power"],
    ["0100010700FF", "Active Power "],
    ["0100010800FF", "Cum Energy"],
    ["0100090800FF", "Cum Energy"],
    ["0100010600FF", "Maximum Demand"],
    ["0100010600FF", "Maximum Demand"],
    ["0100090600FF", "Maximum Demand"],
    ["0100090600FF", "Maximum Demand"],
    ["00005E5B0EFF", "Cum Power On Mints"],
    ["00005E5B00FF", "Cum Tamper Count"],
    ["0000000100FF", "Cum Billing Count"],
    ["0000600200FF", "Cum Programming Count"],
    ["0000000102FF", "Billing Date import mode"],
    ["01000D0000FF", "Avg PF for Billing period"],
    ["0100010801FF", "Cum Energy Kwh for TZ1"],
    ["0100010802FF", "Cum Energy Kwh for TZ2"],
    ["0100010803FF", "Cum Energy Kwh for TZ3"],
    ["0100010804FF", "Cum Energy Kwh for TZ4"],
    ["0100010805FF", "Cum Energy Kwh for TZ5"],
    ["0100010806FF", "Cum Energy Kwh for TZ6"],
    ["0100010807FF", "Cum Energy Kwh for TZ7"],
    ["0100010808FF", "Cum Energy Kwh for TZ8"],
    ["0100090800FF", "Cum Energy Kvah import"],
    ["0100090801FF", "Cum Energy Kvah for TZ1"],
    ["0100090802FF", "Cum Energy Kvah for TZ2"],
    ["0100090803FF", "Cum Energy Kvah for TZ3"],
    ["0100090804FF", "Cum Energy Kvah for TZ4"],
    ["0100090805FF", "Cum Energy Kvah for TZ5"],
    ["0100090806FF", "Cum Energy Kvah for TZ6"],
    ["0100090807FF", "Cum Energy Kvah for TZ7"],
    ["0100090808FF", "Cum Energy Kvah for TZ8"],
    ["0100010600FF", "MD KW"],
    ["0100010601FF", "MD KW TOD 1"],
    ["0100010602FF", "MD KW TOD 2"],
    ["0100010603FF", "MD KW TOD 3"],
    ["0100010604FF", "MD KW TOD 4"],
    ["0100010605FF", "MD KW TOD 5"],
    ["0100010606FF", "MD KW TOD 6"],
    ["0100010607FF", "MD KW TOD 7"],
    ["0100010608FF", "MD KW TOD 8"],
    ["0100090600FF", "MD KVA"],
    ["0100090601FF", "MD KVA TOD 1"],
    ["0100090602FF", "MD KVA TOD 2"],
    ["0100090603FF", "MD KVA TOD 3"],
    ["0100090604FF", "MD KVA TOD 4"],
    ["0100090605FF", "MD KVA TOD 5"],
    ["0100090606FF", "MD KVA TOD 6"],
    ["0100090607FF", "MD KVA TOD 7"],
    ["0100090608FF", "MD KVA TOD 8"],
    ["00005E5B0DFF", "Billing power on minutes"],
  ]
  var heads = [
    {
      name: "Name Plate",
      state: "",
      link: (
        <Dvnameplate filedata={filedata} obisTable={obisTable}></Dvnameplate>
      ),
    },
    {
      name: "Instantaneous",
      state: "",
      link: (
        <Dvinstantaneous
          filedata={filedata}
          obisTable={obisTable}
        ></Dvinstantaneous>
      ),
    },
    {
      name: "Reset Backup",
      state: "",
      link: (
        <Dvresetbackup
          filedata={filedata}
          obisTable={obisTable}
        ></Dvresetbackup>
      ),
    },
    {
      name: "Billing TOD",
      state: "",
      link: (
        <Dvbillingtod filedata={filedata} obisTable={obisTable}></Dvbillingtod>
      ),
    },
    {
      name: "Cumulative Tamper data",
      state: "",
      link: (
        <Dvcumulativetamper
          filedata={filedata}
          obisTable={obisTable}
        ></Dvcumulativetamper>
      ),
    },
    {
      name: "Tamper data",
      state: "",
      link: <Dvtamper filedata={filedata} obisTable={obisTable}></Dvtamper>,
    },
    {
      name: "Unrestored Tamper Details",
      state: "",
      link: "unrestored-tamper-details",
    },
    {
      name: "Daily Consumption",
      state: "",
      link: (
        <Dvdailyload filedata={filedata} obisTable={obisTable}></Dvdailyload>
      ),
    },
    {
      name: "Load Survey",
      state: "",
      link: (
        <Dvblockload filedata={filedata} obisTable={obisTable}></Dvblockload>
      ),
    },
  ]
  const [display, setdisplay] = useState("panel0")
  function handleClick(index) {
    setdisplay("panel" + index)
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            <Upload setfiledata={setfiledata}></Upload>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <Divider />
        <List>
          {heads
            .map(e => e.name)
            .map((text, index) => (
              <ListItem button key={text} onClick={() => handleClick(index)}>
                <ListItemIcon>
                  <DetailsIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {heads.map((e, i) => (
          <Box
            component="div"
            visibility={"panel" + i === display ? "inherit" : "hidden"}
            height={"panel" + i == display ? "inherit" : 0}
            key={i}
          >
            {e.link}
          </Box>
        ))}
      </main>
    </div>
  )
}
