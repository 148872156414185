import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
})

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein }
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
]

export default function BasicTable({ data }) {
  const classes = useStyles()
  if (data) {
    if (data.length) {
      // console.log({ data })
      return (
        <TableContainer component={Paper}>
          <Table
            className={classes.table}
            size="small"
            aria-label="simple table"
          >
            <TableHead>
              {data.map((row, i) => {
                if (i != 0) return null
                else
                  return (
                    <TableRow key={row[0]}>
                      {row.map((e, i) => (
                        <TableCell key={`c${i}`}>{e}</TableCell>
                      ))}
                    </TableRow>
                  )
              })}
            </TableHead>
            <TableBody>
              {data.map((row, i) => {
                if (i == 0) return null
                else
                  return (
                    <TableRow key={row[0]}>
                      {row.map((e, i) => (
                        <TableCell key={`c${i}`}>{e}</TableCell>
                      ))}
                    </TableRow>
                  )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )
    } else return null
  } else return null
}
