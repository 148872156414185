import React, { useState, useEffect, useCallback } from "react"
import Dropzone from "react-dropzone"
import { makeStyles } from "@material-ui/core/styles"
import { Button } from "@material-ui/core"
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))
const Upload = ({ setfiledata }) => {
  const [value, setvalue] = useState()
  const [displaytext, setdisplaytext] = useState(
    "Drag 'n' drop file here, or click to select"
  )
  const [fileName, setfileName] = useState()
  const classes = useStyles()
  function getSection(data, sectionName) {
    var start = data.toString().toLowerCase().indexOf(sectionName.toLowerCase())
    var end = data.length
    var blockBuff = data.slice(start, end)
    var end = blockBuff.indexOf("END")
    var blockBuff = blockBuff.slice(0, end)
    var blockFullArr = blockBuff.toString().split(",")
    blockFullArr.splice(-1, 1)
    blockFullArr.shift()
    return blockFullArr
  }
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()

      reader.onabort = () => console.log("file reading was aborted")
      reader.onerror = () => console.log("file reading has failed")
      reader.onload = () => {
        // Do whatever you want with the file contents
        var filedata = reader.result
        setfiledata(filedata)
        var serial = getSection(filedata, "Name plate details")[1]
        setdisplaytext("Serial " + serial)
      }
      reader.readAsBinaryString(file)
    })
  }, [])
  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <p>{displaytext}</p>
          </div>
        </section>
      )}
    </Dropzone>
  )
}

export default Upload
