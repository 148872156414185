import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Dataview from "../components/dataview"
import PermanentDrawerLeft from "../components/dataviewPermanentDrawerLeft"

const IndexPage = () => (
  <Layout>
    <PermanentDrawerLeft></PermanentDrawerLeft>
  </Layout>
)

export default IndexPage
