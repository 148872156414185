import { Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import BasicTable from "./basicTable"

const Dvblockload = ({ filedata, obisTable }) => {
  const [data, setdata] = useState()
  useEffect(() => {
    if (filedata) getData(filedata)
  }, [filedata])

  function getData(filedata) {
    const obisTable_billing = [
      "01000D0000FF",
      "0100010800FF",
      "0100010801FF",
      "0100010802FF",
      "0100010803FF",
      "0100010804FF",
      "0100010805FF",
      "0100010806FF",
      "0100090800FF",
      "0100090801FF",
      "0100090802FF",
      "0100090803FF",
      "0100090804FF",
      "0100090805FF",
      "0100090806FF",
      "0100010600FF",
      "0100010600FF",
      "0100090600FF",
      "0100090600FF",
      "00005E5B0DFF",
      "0100010601FF",
      "0100010601FF",
    ]
    var arr = [["Date Time", "Voltage", "kWh", "kVAh"]]
    var data = getSection(filedata, "Block load data value")
    var dataObis = getSection(filedata, "Block load data OBIS")
    var scalar = getSection(filedata, "Block load scalar value")
    var scalarObis = getSection(filedata, "Block load scalar OBIS")
    for (var i = 0; i < data.length; i += dataObis.length) {
      var subarr = []
      // for (var j = 0; j < dataObis.length; j++) subarr.push(data[i + j])
      arr.splice(1, 0, [
        data[i],
        data[i + 1], // / 10,
        data[i + 2], // / 100,
        data[i + 3], // / 100,
      ])
    }
    // console.log({ data, dataObis, scalar, scalarObis, arr })
    setdata(arr)
  }
  function getSection(data, sectionName) {
    var start = data.toString().toLowerCase().indexOf(sectionName.toLowerCase())
    var end = data.length
    var blockBuff = data.slice(start, end)
    var end = blockBuff.indexOf("END")
    var blockBuff = blockBuff.slice(0, end)
    var blockFullArr = blockBuff.toString().split(",")
    blockFullArr.splice(-1, 1)
    blockFullArr.shift()
    return blockFullArr
  }

  return (
    <div>
      <Typography variant="h6">Block Load</Typography>
      <BasicTable data={data}></BasicTable>
    </div>
  )
}

export default Dvblockload
