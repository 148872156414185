import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Dvnameplate from "./dvnameplate"
import Upload from "./upload"
import Dvinstantaneous from "./dvinstantaneous"
import Dvresetbackup from "./dvresetbackup"
import Dvbillingtod from "./dvbillingtod"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))

const Dataview = () => {
  const classes = useStyles()
  const [filedata, setfiledata] = useState("")
  const obisTable = [
    ["0000010000FF", "Real Date and Time"],
    ["01000C0700FF", "Voltage"],
    ["01005E5B0EFF", "Current"],
    ["01000B0700FF", "Phase Current"],
    ["01005B0700FF", "Neutral Current"],
    ["01000D0700FF", "Signed PF"],
    ["01000E0700FF", "Frequency"],
    ["0100090700FF", "Apparent Power"],
    ["0100010700FF", "Active Power "],
    ["0100010800FF", "Cum Energy"],
    ["0100090800FF", "Cum Energy"],
    ["0100010600FF", "Maximum Demand"],
    ["0100010600FF", "Maximum Demand"],
    ["0100090600FF", "Maximum Demand"],
    ["0100090600FF", "Maximum Demand"],
    ["00005E5B0EFF", "Cum Power On Mints"],
    ["00005E5B00FF", "Cum Tamper Count"],
    ["0000000100FF", "Cum Billing Count"],
    ["0000600200FF", "Cum Programming Count"],
    ["0000000102FF", "Billing Date import mode"],
    ["01000D0000FF", "Avg PF for Billing period"],
    ["0100010801FF", "Cum Energy Kwh for TZ1"],
    ["0100010802FF", "Cum Energy Kwh for TZ2"],
    ["0100010803FF", "Cum Energy Kwh for TZ3"],
    ["0100010804FF", "Cum Energy Kwh for TZ4"],
    ["0100010805FF", "Cum Energy Kwh for TZ5"],
    ["0100010806FF", "Cum Energy Kwh for TZ6"],
    ["0100010807FF", "Cum Energy Kwh for TZ7"],
    ["0100010808FF", "Cum Energy Kwh for TZ8"],
    ["0100090800FF", "Cum Energy Kvah import"],
    ["0100090801FF", "Cum Energy Kvah for TZ1"],
    ["0100090802FF", "Cum Energy Kvah for TZ2"],
    ["0100090803FF", "Cum Energy Kvah for TZ3"],
    ["0100090804FF", "Cum Energy Kvah for TZ4"],
    ["0100090805FF", "Cum Energy Kvah for TZ5"],
    ["0100090806FF", "Cum Energy Kvah for TZ6"],
    ["0100090807FF", "Cum Energy Kvah for TZ7"],
    ["0100090808FF", "Cum Energy Kvah for TZ8"],
    ["0100010600FF", "MD KW"],
    ["0100010601FF", "MD KW TOD 1"],
    ["0100010602FF", "MD KW TOD 2"],
    ["0100010603FF", "MD KW TOD 3"],
    ["0100010604FF", "MD KW TOD 4"],
    ["0100010605FF", "MD KW TOD 5"],
    ["0100010606FF", "MD KW TOD 6"],
    ["0100010607FF", "MD KW TOD 7"],
    ["0100010608FF", "MD KW TOD 8"],
    ["0100090600FF", "MD KVA"],
    ["0100090601FF", "MD KVA TOD 1"],
    ["0100090602FF", "MD KVA TOD 2"],
    ["0100090603FF", "MD KVA TOD 3"],
    ["0100090604FF", "MD KVA TOD 4"],
    ["0100090605FF", "MD KVA TOD 5"],
    ["0100090606FF", "MD KVA TOD 6"],
    ["0100090607FF", "MD KVA TOD 7"],
    ["0100090608FF", "MD KVA TOD 8"],
    ["00005E5B0DFF", "Billing power on minutes"],
  ]
  var heads = [
    {
      name: "Name Plate Details",
      state: "",
      link: (
        <Dvnameplate filedata={filedata} obisTable={obisTable}></Dvnameplate>
      ),
    },
    {
      name: "Instantaneous Data",
      state: "",
      link: (
        <Dvinstantaneous
          filedata={filedata}
          obisTable={obisTable}
        ></Dvinstantaneous>
      ),
    },
    {
      name: "ResetBackup Data",
      state: "",
      // link: (
      //   <Dvresetbackup
      //     filedata={filedata}
      //     obisTable={obisTable}
      //   ></Dvresetbackup>
      // ),
    },
    {
      name: "Billing TOD data",
      state: "",
      link: (
        <Dvbillingtod filedata={filedata} obisTable={obisTable}></Dvbillingtod>
      ),
    },
    {
      name: "Cumulative Tamper data",
      state: "",
      link: "cumulative-tamper-data",
    },
    { name: "Tamper data", state: "", link: "tamper-data" },
    {
      name: "Unrestored Tamper Details",
      state: "",
      link: "unrestored-tamper-details",
    },
    { name: "Daily Consumption", state: "", link: "daily-consumption" },
    { name: "Load Survey", state: "", link: "load-survey" },
  ]

  return (
    <div className={classes.root}>
      <Upload setfiledata={setfiledata}></Upload>
      {heads.map((e, i) => (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${i + 1}a-content`}
            id={`panel${i + 1}a-header`}
          >
            <Typography className={classes.heading}>{e.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>{e.link}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  )
}
export default Dataview
