import { Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import BasicTable from "./basicTable"

const Dvinstantaneous = ({ filedata, obisTable }) => {
  const [data, setdata] = useState()
  useEffect(() => {
    getInstantData(filedata)
  }, [filedata])
  function getInstantData(arr) {
    var instant = getSection(arr, "Instant data value")
    var instantObis = getSection(arr, "Instant data OBIS")
    var instantScalar = getSection(arr, "Instant scalar value")
    var instantScalarObis = getSection(arr, "Instant scalar OBIS")
    var arr = instant.map((e, i) => {
      var indx = instantScalarObis.indexOf(instantObis[i])
      var unit = ""
      var name = obisTable.filter(e => e[0] == instantObis[i])
      if (name.length) name = name[0][1]
      else name = instantObis[i]
      if (indx > -1) {
        var decPos = e.length + parseInt(instantScalar[indx].split("|")[0])
        unit = instantScalar[indx].split("|")[1]
        if (decPos != e.length && decPos != -1)
          e = e.slice(0, decPos) + "." + e.slice(decPos)
      }
      return [i + 1, name, `${e} ${unit}`]
    })
    setdata(arr)
  }
  function getSection(data, sectionName) {
    var start = data.toString().toLowerCase().indexOf(sectionName.toLowerCase())
    var end = data.length
    var blockBuff = data.slice(start, end)
    var end = blockBuff.indexOf("END")
    var blockBuff = blockBuff.slice(0, end)
    var blockFullArr = blockBuff.toString().split(",")
    blockFullArr.splice(-1, 1)
    blockFullArr.shift()
    return blockFullArr
  }

  return (
    <div>
      <Typography variant="h6">Instantaneous</Typography>
      <BasicTable data={data}></BasicTable>
    </div>
  )
}

export default Dvinstantaneous
