import { Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import BasicTable from "./basicTable"

const Dvresetbackup = ({ filedata, obisTable }) => {
  const [data, setdata] = useState()
  useEffect(() => {
    getData(filedata)
  }, [filedata])
  function getInstantData(arr) {
    var instant = getSection(arr, "Instant data value")
    var instantObis = getSection(arr, "Instant data OBIS")
    var instantScalar = getSection(arr, "Instant scalar value")
    var instantScalarObis = getSection(arr, "Instant scalar OBIS")
    var arr = instant.map((e, i) => {
      var indx = instantScalarObis.indexOf(instantObis[i])
      var unit = ""
      var name = obisTable.filter(e => e[0] == instantObis[i])
      if (name.length) name = name[0][1]
      else name = instantObis[i]
      if (indx > -1) {
        var decPos = e.length + parseInt(instantScalar[indx].split("|")[0])
        unit = instantScalar[indx].split("|")[1]
        if (decPos != e.length && decPos != -1)
          e = e.slice(0, decPos) + "." + e.slice(decPos)
      }
      return [i + 1, name, `${e} ${unit}`]
    })
  }
  function getData(data) {
    if (data == "") return null
    const obisTable_resetBackup = [
      "0000000102FF",
      "01000D0000FF",
      "0100010800FF",
      "0100090800FF",
      "0100010600FF",
      "0100090600FF",
    ]
    var values = getSection(data, "Billing data value")
    var valuesObis = getSection(data, "Billing data OBIS")
    var scalar = getSection(data, "Billing scalar value")
    var scalarObis = getSection(data, "Billing scalar OBIS")
    var arr = obisTable_resetBackup.map((e, i) => {
      var name = obisTable.filter(e => e[0] == valuesObis[i])
      if (name.length) name = name[0][1]
      else name = valuesObis[i]
      if (name.length) name = name[0][1]
      else name = valuesObis[i]
      var value = values[valuesObis.indexOf(e)]
      var scalarIndx = scalarObis.indexOf(e)
      var unit = ""
      if (scalarIndx != -1) {
        var decPos = value.length + parseInt(scalar[scalarIndx].split("|")[0])
        if (decPos < value.length && decPos != -1)
          value = value.slice(0, decPos) + "." + value.slice(decPos)
        if (decPos == 0) value = 0 + value
        unit = scalar[scalarIndx].split("|")[1]
      }
      return [i, name, value, unit]
    })
    setdata(arr)

    // console.log(rbd);
  }
  function getSection(data, sectionName) {
    var start = data.toString().toLowerCase().indexOf(sectionName.toLowerCase())
    var end = data.length
    var blockBuff = data.slice(start, end)
    var end = blockBuff.indexOf("END")
    var blockBuff = blockBuff.slice(0, end)
    var blockFullArr = blockBuff.toString().split(",")
    blockFullArr.splice(-1, 1)
    blockFullArr.shift()
    return blockFullArr
  }

  return (
    <div>
      <Typography variant="h6">Reset Backup</Typography>
      <BasicTable data={data}></BasicTable>
    </div>
  )
}

export default Dvresetbackup
