import { Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import moment from "moment"
import _ from "lodash"
import BasicTable from "./basicTable"

const Dvtampersub = ({ name, dlmsname, filedata }) => {
  const [table, settable] = useState()
  const tamperList = [
    { eid: 7, tname: "Over Voltage in any Phase Occurrence" },
    { eid: 8, tname: "Over Voltage in any Phase Restoration" },
    { eid: 9, tname: "Low Voltage in any Phase Occurrence" },
    { eid: 10, tname: "Low Voltage in any Phase Restoration" },
    { eid: 69, tname: "Earth Occurrence" },
    { eid: 70, tname: "Earth Restoration" },
    { eid: 51, tname: "Reverse Occurrence" },
    { eid: 52, tname: "Reverse Restoration" },
    { eid: 67, tname: "Over Current Occurrence" },
    { eid: 68, tname: "Over Current Restoration" },
    { eid: 101, tname: "Power Fail Occurrence" },
    { eid: 102, tname: "Power Fail Restoration" },
    { eid: 151, tname: "Real Time Clock Date and Time" },
    { eid: 152, tname: "Demand Integration Period" },
    { eid: 153, tname: "Profile Capture Period" },
    { eid: 154, tname: "Single-action Schedule for Billing Dates" },
    { eid: 155, tname: "Activity Calendar for Time Zones" },
    { eid: 157, tname: "New Firmware Activated" },
    { eid: 158, tname: "Load limit(KW) set" },
    { eid: 201, tname: "Abnormal External Magnetic Influence--Occurrence" },
    { eid: 202, tname: "Abnormal External Magnetic Influence--Restoration" },
    { eid: 203, tname: "Neutral Disturbance--Occurrence" },
    { eid: 204, tname: "Neutral Disturbance--Restoration" },
    { eid: 207, tname: "Single Wire Operation(Neutral Missing)--Occurrence" },
    {
      eid: 208,
      tname: "Single Wire Operation(Neutral Missing)--Restoration",
    },
    { eid: 251, tname: "Meter Cover Opening--Occurrence" },
  ]
  useEffect(() => {
    makeData(filedata, dlmsname, tamperList)
  }, [filedata])
  function makeData(filedata, dlmsname, tamperList) {
    var tableL = [
      [
        "Date Time",
        "Duration (minutes)",
        "Event Name",
        "Voltage (V)",
        "Current (A)",
        "PF",
        "kWh",
        "kVah",
      ],
    ]
    var section = getSection(filedata, dlmsname)
    var obisS = getSection(filedata, "Event scalar Obis")

    for (var i = 0; i < section.length; i += 6)
      tableL.splice(1, 0, [
        section[i + 0],
        "",
        tamperList.filter(e => e.eid == section[i + 1])[0].tname,
        section[i + 3] / 10,
        section[i + 2] / 100,
        section[i + 4] / 100,
        section[i + 5] / 100,
        "NA",
      ])
    // console.log({ tableL })
    for (var i = 2; i < tableL.length; i += 2) {
      var t1 = moment(tableL[i - 1][0], "DD/MM/YYYY HH:mm:ss")
      var t2 = moment(tableL[i][0], "DD/MM/YYYY HH:mm:ss")
      var td = _.round(t2.diff(t1, "minutes", true), 1)

      tableL[i][1] = td
    }
    settable(tableL)
  }
  function getSection(data, sectionName) {
    var start = data.toString().toLowerCase().indexOf(sectionName.toLowerCase())
    var end = data.length
    var blockBuff = data.slice(start, end)
    var end = blockBuff.indexOf("END")
    var blockBuff = blockBuff.slice(0, end)
    var blockFullArr = blockBuff.toString().split(",")
    blockFullArr.splice(-1, 1)
    blockFullArr.shift()
    return blockFullArr
  }
  return (
    <div>
      <Typography variant="body1">{name}</Typography>
      <BasicTable data={table}></BasicTable>
    </div>
  )
}

export default Dvtampersub
