import { Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import BasicTable from "./basicTable"

const Dvnameplate = ({ filedata }) => {
  const [npdata, setnpdata] = useState()
  useEffect(() => {
    getNamePlate(filedata)
  }, [filedata])
  function getNamePlate(filedata) {
    if (filedata) {
      var blockFullArr = getSection(filedata, "Name plate details")
      // console.log({ blockFullArr })
      var arr = [
        ["Data upload Time (BCS)", ""],
        ["Logical Device Name", blockFullArr[0]],
        ["Meter Serial Number", blockFullArr[1]],
        ["Firmware Version", blockFullArr[3]],
        ["Meter Type", "TBD"],
        ["Meter RTC", "TBD"],
        ["Category", "TBD"],
        ["Current Rating", blockFullArr[8]],
        ["Manufacturer Year", blockFullArr[9]],
        ["Demand Integration Period", blockFullArr[9]],
        ["Profile Capture Period", blockFullArr[10]],
        ["RS485 Device Address", blockFullArr[11]],
        ["CMRI Datetime", blockFullArr[12]],
        ["CMRI Id", blockFullArr[13]],
        ["CMRI->PC Dwld Time", blockFullArr[14]],
        ["Meter Manufacturer", blockFullArr[2]],
      ]
      setnpdata(arr)
    }
  }
  function getSection(data, sectionName) {
    var start = data.toString().toLowerCase().indexOf(sectionName.toLowerCase())
    var end = data.length
    var blockBuff = data.slice(start, end)
    var end = blockBuff.indexOf("END")
    var blockBuff = blockBuff.slice(0, end)
    var blockFullArr = blockBuff.toString().split(",")
    blockFullArr.splice(-1, 1)
    blockFullArr.shift()
    return blockFullArr
  }

  return (
    <div>
      <Typography variant="h6">Name Plate</Typography>{" "}
      <BasicTable data={npdata}></BasicTable>
    </div>
  )
}

export default Dvnameplate
