import { Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import BasicTable from "./basicTable"
import Moment from "react-moment"

const Dvcumulativetamper = ({ filedata, obisTable }) => {
  const [data, setdata] = useState()
  useEffect(() => {
    getData(filedata)
  }, [filedata])

  function getData(data) {
    var tamperList = [
      { eid: 7, tname: "Over Voltage in any Phase Occurrence" },
      { eid: 8, tname: "Over Voltage in any Phase Restoration" },
      { eid: 9, tname: "Low Voltage in any Phase Occurrence" },
      { eid: 10, tname: "Low Voltage in any Phase Restoration" },
      { eid: 69, tname: "Earth Occurrence" },
      { eid: 70, tname: "Earth Restoration" },
      { eid: 51, tname: "Reverse Occurrence" },
      { eid: 52, tname: "Reverse Restoration" },
      { eid: 67, tname: "Over Current Occurrence" },
      { eid: 68, tname: "Over Current Restoration" },
      { eid: 101, tname: "Power Fail Occurrence" },
      { eid: 102, tname: "Power Fail Restoration" },
      { eid: 151, tname: "Real Time Clock Date and Time" },
      { eid: 152, tname: "Demand Integration Period" },
      { eid: 153, tname: "Profile Capture Period" },
      { eid: 154, tname: "Single-action Schedule for Billing Dates" },
      { eid: 155, tname: "Activity Calendar for Time Zones" },
      { eid: 157, tname: "New Firmware Activated" },
      { eid: 158, tname: "Load limit(KW) set" },
      { eid: 201, tname: "Abnormal External Magnetic Influence--Occurrence" },
      { eid: 202, tname: "Abnormal External Magnetic Influence--Restoration" },
      { eid: 203, tname: "Neutral Disturbance--Occurrence" },
      { eid: 204, tname: "Neutral Disturbance--Restoration" },
      { eid: 207, tname: "Single Wire Operation(Neutral Missing)--Occurrence" },
      {
        eid: 208,
        tname: "Single Wire Operation(Neutral Missing)--Restoration",
      },
      { eid: 251, tname: "Meter Cover Opening--Occurrence" },
    ]
    var sections = [
      "Voltage event data",
      "Current event data",
      "Power fail event data",
      "Transaction event data",
      "Other event data",
      "Non-rollover event data",
    ]
    var valuesS = getSection(data, "Event scalar value")
    var obisS = getSection(data, "Event scalar Obis")
    var events = []
    for (var i = 0; i < sections.length; i++) {
      var values = getSection(data, `${sections[i]} value`)
      var obis = getSection(data, `${sections[i]} OBIS`)
      if (obis.length) {
        var totalEvents = values.length / obis.length
        for (var j = 0; j < totalEvents; j++) {
          var valuesObj = []
          for (var k = 2; k < obis.length; k++) {
            var valueName = obisTable.filter(e => e[0] == obis[k])[0]
            if (!valueName) {
              obisTable.push([obis[k], "Unknown Obis"])
            }
            var valueScalar = valuesS[
              obisS.indexOf(obisTable.filter(e => e[0] == obis[k])[0][0])
            ].split("|")
            valuesObj.push({
              type: obisTable.filter(e => e[0] == obis[k])[0][1],
              value: addDecimal(values[k], valueScalar),
              unit: valueScalar[1],
            })
          }
          var tamper = tamperList.filter(
            e => e.eid == values[j * obis.length + 1]
          )
          if (tamper.length) tamper = tamper[0].tname
          else
            tamper =
              "Error. Tamper not in table. EID:" + values[j * obis.length + 1]

          events.push({
            section: sections[i],
            date: <Moment format="DD/MM/YYYY hh:mm:ss">{values[0]}</Moment>,
            tamper: tamper,
            values: valuesObj,
          })
        }
      }
    }
    var arr = events.map((e, i) => {
      var duration = ""
      if (e.tamper.indexOf("Restoration") != -1)
        duration = events[i].date - events[i + 1].date
      if (e.values.length)
        return [
          i + 1,
          e.tamper,
          <Moment format="MMM DD YYYY, h:mm:ss a">{e.date}</Moment>,
          duration,
          "",
          "",
          e.values[0].value,
          e.values[1].value,
          e.values[2].value,
          e.values[3].value,
        ]
      else
        return [
          i + 1,
          e.tamper,
          e.date.format("MMM Do YYYY, h:mm:ss a"),
          duration,
          "",
          "",
          "",
          "",
          "",
          "",
        ]
    })
    arr.unshift([
      "S.No",
      "Tamper",
      "Time",
      "Duration",
      "",
      "",
      "I",
      "V",
      "PF",
      "KWH",
    ])
    setdata(arr)
  }
  function getSection(data, sectionName) {
    var start = data.toString().toLowerCase().indexOf(sectionName.toLowerCase())
    var end = data.length
    var blockBuff = data.slice(start, end)
    var end = blockBuff.indexOf("END")
    var blockBuff = blockBuff.slice(0, end)
    var blockFullArr = blockBuff.toString().split(",")
    blockFullArr.splice(-1, 1)
    blockFullArr.shift()
    return blockFullArr
  }
  function addDecimal(value, scalar) {
    if (scalar != -1) {
      var decPos = value.length + parseInt(scalar)
      if (decPos < value.length && decPos != -1)
        value = value.slice(0, decPos) + "." + value.slice(decPos)
      if (decPos == 0) value = 0 + value
    }
    return value
  }
  return (
    <div>
      <Typography variant="h6">Cumulative Tamper</Typography>
      <BasicTable data={data}></BasicTable>
    </div>
  )
}

export default Dvcumulativetamper
