import { Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import BasicTable from "./basicTable"
import Moment from "react-moment"
import Dvtampersub from "./dvtampersub"

const Dvtamper = ({ filedata, obisTable }) => {
  const [data, setdata] = useState()
  // useEffect(() => {
  //   getData(filedata)
  // }, [filedata])

  const sections = [
    { name: "Earth Loading", dlmsname: "Current event data" },
    { name: "Low/Over Voltage in any Phase", dlmsname: "Voltage event data" },
    { name: "Meter Cover Opening", dlmsname: "Non-rollover event data" },
    { name: "Over Voltage in any Phase", dlmsname: "Transaction event data" },
    { name: "Power failure", dlmsname: "Power fail event data" },
    {
      name: "Single wire operation(netural missing)",
      dlmsname: "Other event data",
    },
  ]
  return (
    <div>
      <Typography variant="h6">Tamper</Typography>
      {sections.map((e, i) => (
        <Dvtampersub
          name={e.name}
          dlmsname={e.dlmsname}
          filedata={filedata}
          var
          key={i}
        ></Dvtampersub>
      ))}
    </div>
  )
}

export default Dvtamper
